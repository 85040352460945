/* Base */
@import "./base/reset";
@import "./base/vars";
@import "./base/base";
@import "./base/utils";
@import "./base/containers";
@import "./base/sticky-footer";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@700&display=swap');

/* Blocks */
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "blocks/_logo.scss";
@import "blocks/_mobile-nav.scss";
@import "blocks/_nav-icon.scss";

html, body {
	scroll-behavior: smooth;
 }
[class$=__container]{
	max-width: 1210px;
	padding: 0 15px;
	margin: 0 auto;
}



.form {

	&__text {
		font-weight: 400;
		font-size: 25px;
		line-height: 150%;
		color: #fbfbfb;
		margin: 10px 0px 0px 0px;
		@media(max-width:$breakpoint480){
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			
		}
	}
}



.consult-form {
	margin: 50px 0px 0px 0px;
	@media(max-width:$breakpoint480){
		margin:20px 0px 0px 0px;
	}
	
	display: flex;

	align-items: flex-start;
	@media(max-width:$breakpoint480){
		align-items: center;
	}
	flex-direction: column;
	gap:30px;
	input{
		max-width: 376px;
		width: 100%;
		border-radius: 4px;
		border: 1px solid var(--Gray-5, #E0E0E0);
		background: transparent;
		height: 55px;
		padding-left: 15px;
		font-family: var(--second-fam);
		font-weight: 700;
		font-size: 16px;
		color: #fff;
&::placeholder{
	font-family: var(--second-fam);
	font-weight: 700;
	font-size: 16px;
	color: #fff;
}
	}
}

.consult-btn {
	border-radius: 4px;
	background: transparent;
	border: 1px solid #fff;
height: 55px;
max-width: 376px;
width: 100%;
font-family: var(--second-fam);
font-weight: 700;
font-size: 16px;
text-align: center;
color: #fff;
transition: all .3s ease;
&:hover{
	background:  #216f66;
}
}
.messages {
	color: red;
}

.hero {
	padding: 175px 0;
	@media(max-width:$breakpoint1200){
		padding: 100px 0;
	}
	@media(max-width:$breakpoint480){
		padding: 50px 0 ;
	}
	background-image: url(../img/header-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media(max-width:$breakpoint1200){
			flex-direction: column;
		}
	}

	&__content {
		max-width: 672px;
	}

	&__title {
		font-weight: 700;
		font-size: 40px;
		color: #fff;
		@media(max-width:$breakpoint1200){
			text-align: center;
		}
		@media(max-width:$breakpoint768){
			font-size: 30px;
		}
		@media(max-width:$breakpoint480){
			font-size: 25px;
		}
	}

	&__text {
		margin: 30px 0px 0px 0px;
		font-weight: 400;
		font-size: 16px;
		line-height: 187%;
		color: #fff;
		@media(max-width:$breakpoint1200){
			text-align: center;
		}
		@media(max-width:$breakpoint480){
			font-size: 14px;
		}
	}

	&__form {
		max-width: 376px;
		width: 100%;
	}
}

h2{
	font-weight: 700;
font-size: 35px;
color: #2e2e2e;
@media(max-width:$breakpoint480){
	font-size: 20px;
	text-align: center;
}
}
h3{
	font-family: var(--second-fam);
	font-weight: 700;
	font-size: 30px;
	color: #fff;
	@media(max-width:$breakpoint480){
		font-size: 20px;
	}
}
.why {
	padding: 100px 0 50px 0;
	@media(max-width:$breakpoint480){
		padding: 50px 0 30px;
	}
	&__container {
		display: flex;
		gap:40px;
		align-items: center;
		@media(max-width:$breakpoint1200){
			flex-direction: column;
		}
	}

	&__img {
		img{
			margin: 35px 0px 0px 0px;
			@media(max-width:$breakpoint480){
				width: 312px;
				height: 210px;
			}
		}
	}

	&__title {
	}

	&__content {
		color: #fff;
		@media(max-width:$breakpoint480){
			padding: 20px;
		}
		padding: 60px 52px;
		background: #077205;
		max-width: 644px;
	}

	
	&__list {
		margin: 20px 0px 0px 20px;
		li{
			list-style: disc;
			font-weight: 400;
			font-size: 16px;
			color: #fff;
			@media(max-width:$breakpoint480){
				font-size: 12px;
			}
		}
	}

	&__text {
		margin: 30px 0px 0px 0px;
		font-weight: 400;
		font-size: 16px;
		color: #fff;
		@media(max-width:$breakpoint480){
			font-size: 12px;
		}
	}
}

.what {

	&__container {
		display: flex;
		gap: 20px;
		justify-content: space-between;
		@media(max-width:$breakpoint1200){
			flex-direction: column;
			align-items: center;
		}

	}

	&__content {
		max-width: 513px;
		@media(max-width:$breakpoint1200){
			order:2;
		}
	}

	&__subtitle {
		margin: 0px 0px 20px 0px;
	}

	&__text {
		margin: 5px 0px 0px 0px;
		font-weight: 400;
		font-size: 17px;
		color: #5e5e5e;
		@media(max-width:$breakpoint480){
			font-size: 12px;
		}
	
	}

	&__list {

		font-weight: 400;
		font-size: 17px;
		color: #5e5e5e;
		margin: 5px 0px 0px 20px;
		@media(max-width:$breakpoint480){
			font-size: 12px;
		}
		li{
			list-style: disc;
		}
	}

	&__img {
		img{
			@media(max-width:$breakpoint480){
				width: 360px;
				height: 210px;
			}
		}
	
	}
}


.info {
	padding: 50px 0;

	&__container {
		display: flex;
		justify-content: space-between;
		padding: 50px;
		background: #f5f5f5;
		align-items: center;
		@media(max-width:$breakpoint1200){
			flex-direction: column;
			gap:30px;
		}
		@media(max-width:$breakpoint480){
			padding: 30px;
		}
	}
	&__wrap{
		max-width: 620px;
		
	}
	&__title {
	}

	&__text {
		margin: 39px 0px 0px 0px;
		font-weight: 400;
		font-size: 16px;
		line-height: 187%;
		color: #5e5e5e;
		@media(max-width:$breakpoint480){
			margin: 10px 0px 0px 0px;
			font-weight: 400;
			font-size: 12px;
			text-align: center;
			color: #5e5e5e;
		}
		span{
			font-weight: 700;
			color: #077205;
		}
	}

	&__btn {
		font-family: var(--second-fam);
		padding: 20px 110px;
		border: 2px #000 solid;
		font-weight: 700;
		font-size: 20px;
		text-align: center;
		color: #070707;
		cursor: pointer;
		transition: all .3s ease;
		@media(max-width:$breakpoint480){
			font-weight: 700;
			font-size: 16px;
			text-align: center;
			color: #070707;
		}
		&:hover{
			background: #216f66;
			color: #fff;
			border: 2px solid #fff;
		}
	}	
}

.revs {
	padding: 50px 0;
	position: relative;
	&__container {
	}

	&__title {
		margin: 0px 0px 30px 0px;
		text-align: center;
	}

	&__slider {
	}
}
.slider {
	
	&__item {
		padding: 0 50px;
		display: flex!important;
		flex-direction: column;
		align-items: center;
	
		
	}
	&__wrap{
		background: #077205;

		max-width: 1000px;
		width: 100%;
		padding: 50px 120px;
		@media(max-width:$breakpoint1200){
			padding: 30px 50px;
			max-width: 700px;
		}
		@media(max-width:$breakpoint992){
			max-width: 500px;
		}
		@media(max-width:$breakpoint768){
			padding: 20px;
			max-width: 400px;
		}
	}
	&__text {
		font-style: italic;
		font-weight: 400;
		font-size: 30px;
		@media(max-width:$breakpoint1200){
			font-size: 25px;
		}
		@media(max-width:$breakpoint768){
			font-size: 20px;
			border-left: none;
			padding-left: 0;
		}
		@media(max-width:$breakpoint480){
			font-style: italic;
			font-weight: 400;
			font-size: 14px;
			color: #e6e6e6;
		
	}
	
		color: #e6e6e6;
		padding-left: 25px;
		border-left: 1px solid #fff;
	}

	&__bottom {
		margin: 30px 0px 0px 0px;
		@media(max-width:$breakpoint480){
			margin: 20px 0px 0px 0px;
		}
		display: flex;
		align-items: center;
		gap:25px;
	}

	&__icon {
	}

	&__name {
		font-weight: 700;
		font-size: 30px;
		@media(max-width:$breakpoint1200){
			font-size: 25px;
		}
		@media(max-width:$breakpoint768){
			font-size: 20px;
		}
		color: #fff;
	}
}

/* Стрелка */
.slick-arrow{
	
}
/* Стрелка влево */
.slick-arrow.slick-prev{
	background-image: url(../img/arrow-left.svg);
	background-repeat: no-repeat;
	width: 26px;
	height: 46px;
	font-size: 0;
	position: absolute;
	left: 0;
	bottom:50%;
	transform: translateY(50%);
	z-index: 2;
	transition: all .3s ease;
	&:hover{
		left: -5px;
	}
}
/* Стрелка вправо */
.slick-arrow.slick-next{
	background-image: url(../img/arrow-right.svg);
	background-repeat: no-repeat;
	width: 26px;
	height: 46px;
	font-size: 0;
	position: absolute;
	right: 0;
	bottom: 50%;
	transform: translateY(30%);
	z-index: 2;
	transition: all .3s ease;
	&:hover{
		right:  -5px;
	}
}
/* Стрелка не активная */
.slick-arrow.slick-disabled{}
