
.header__nav {
	position: relative;
	
	
	justify-content: center;
}

.nav__icon {
	display: none;

}
.nav__icon.active::before{
transform: rotate(45deg);
top:9px;
}
.nav__icon.active::after{
	transform: rotate(-45deg);
	bottom:9px;
	}

.nav__icon.active span{
	transform: scale(0);
}

@media (max-width:767.98px) {
	body.lock{
		overflow: hidden;
	}
	.nav__body {
		overflow: auto;
		position: fixed;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translate(-1000px);
		background-image: url(../img/header-bg.jpg);
		transition: all .3s ease;
		z-index: 100;
	}
	.nav__body.active{
		transform: translate(0);
	}
}

@media (max-width:767.98px) {
	.nav__icon{
		display: block;
		position: relative;
		width: 30px;
		height: 20px;
		z-index: 104;
	}
	.nav__icon.active{
		&::after,
		&::before{
			background-color: #fff;
		}
	}
	.nav__icon::before,
	.nav__icon::after{
		content: '';
		background-color: #000;
	
		position: absolute;
		width: 100%;
		height: 2px;
		left:0;
		transition: all .3s ease;
	}
	.nav__icon::before{
		top:0;
	}
	.nav__icon::after{
		bottom:0;
	}
	.nav__icon span{
		top:9px;
		left: 0;
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #000;
		@media(max-width:$breakpoint768){
			&:active{
background-color: #fff;
			}
			
		}
		transition: all .3s ease;
	}
}
.nav__list {
	display: flex;
	gap:50px;
	position: relative;
	z-index: 4;
	
	font-weight: 500;
	font-size: 20px;
	color: #216f66;
	
}
@media (max-width:767.98px) {
	.nav__list{
		align-items: center;
		padding-top: 90px;
		flex-direction: column;
		
	}
}
.nav__link {

	color: #000;
transition: all .3s ease;
&:hover{
	color: var(--hover-color);
}

}
@media(max-width:$breakpoint768){
	.nav__link {

		color: #fff;
		
		}
}
