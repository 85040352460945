.header {
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 50px 0;
		@media(max-width:$breakpoint480){
			padding: 30px 0;
		}
	}

}



