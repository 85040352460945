.footer {
	padding: 50px 0;
	background: #2E2E2E;
	color: white;
	a{
		color: white;
		transition: all .3s ease;
		&:hover{
			color:#88B7A4;
		}
	}
}

.footer {

	&__container {
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media(max-width:$breakpoint768){
			flex-direction: column;
			align-items: center;
		}
	}

	&__logo {
	}

	&__links {
		display: flex;
		gap:40px;
		@media(max-width:$breakpoint992){
			flex-direction: column;
			gap: 15px;
		}
		@media(max-width:$breakpoint768){
			margin: 20px 0px 0px 0px;
			text-align: center;
		}
	}

	&__link {
	}

	&__content {
		margin: 20px 0px 0px 0px;
		display: flex;
		justify-content: space-between;
		@media(max-width:$breakpoint768){
			flex-direction: column;
			align-items: center;
		}
	}

	&__text {
		@media(max-width:$breakpoint768){
			text-align: center;
		}
	}

	&__conf {
		align-self: flex-end;
		@media(max-width:$breakpoint768){
			align-self: center;
			margin: 20px 0px 0px 0px;
		}
	}
}

